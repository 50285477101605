import React, { useState, useEffect, useRef } from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartType,
  LineController,
} from 'chart.js';
import Chart from 'chart.js/auto';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
);

const firebaseConfig = {
  // Your Firebase config object here
  apiKey: "AIzaSyA_6IxkPu_20OTANnRRumtqiyJLH1-KpMc",
  authDomain: "mfpacitan.firebaseapp.com",
  databaseURL: "https://mfpacitan-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mfpacitan",
  storageBucket: "mfpacitan.appspot.com",
  messagingSenderId: "1060769127857",
  appId: "1:1060769127857:web:1fea35750734674493b190",
  measurementId: "G-CSFG9N291L"
};

const firebaseApp = initializeApp(firebaseConfig);

const CombinedChartAndData = () => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const [suhu, setSuhu] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const startingData = {
      labels: [new Date().toISOString()],
      datasets: [
        {
          label: "Suhu",
          backgroundColor: "rgba(206, 70, 90, 1)",
          borderColor: "rgba(70, 90, 206, 0.8)",
          pointBackgroundColor: "rgba(255, 0, 0, 1)",
          pointBorderColor: "#fff",
          data: [],
        }
      ]
    };

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: startingData,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100
            }
          }],
          xAxes: [{
            type: 'time',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Date'
            },
            ticks: {
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000'
              }
            }
          }]
        }
      }
    });

    // Set up the listener for the 'suhu' data
    const suhuRef = ref(getDatabase(firebaseApp), 'DATA_SENSOR/DATA_SENSOR_KELEMBABAN');
    const listenerSuhu = onValue(suhuRef, (snapshot) => {
      const value = snapshot.val();
      console.log('Suhu:', value);
      setSuhu(value);
    }, (error) => {
      console.error('Error reading suhu:', error.message);
      // Handle error (e.g., show a toast)
    });

    // Clean up the listener when the component is unmounted
    return () => {
      chartRef.current.destroy();
      off(suhuRef, 'value', listenerSuhu);
    };
  }, []); // Empty dependency array means this effect runs once, like componentDidMount

  useEffect(() => {
    const updateChart = () => {
      const lastDataIndex = chartRef.current.data.labels.length;
      const suhuValue = suhu !== null ? suhu : 0;

      // Create a copy of the current data
      const newData = {
        labels: [...chartRef.current.data.labels],
        datasets: chartRef.current.data.datasets.map((dataset) => ({
          ...dataset,
          data: [...dataset.data],
        })),
      };
      const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      };

      // Update the copied data
      newData.datasets[0].data[lastDataIndex] = suhuValue;
      newData.labels[lastDataIndex] = new Date().toLocaleString(dateOptions);

      // Keep only the last 10 data points
      if (newData.labels.length > 30) {
        newData.labels.shift();
        newData.datasets[0].data.shift();
      }

      // Update the chart with the modified data
      chartRef.current.data = newData;
      chartRef.current.update();
    };

    const intervalId = setInterval(updateChart, 15000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [suhu]); // Include suhu in the dependency array

  return <canvas ref={canvasRef} />;
};

export default CombinedChartAndData;
