import React, { useState, useEffect, useRef } from 'react';
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  TimeScale,
} from 'chart.js';
import Chart from 'chart.js/auto';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { DateTime } from 'luxon';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const firebaseConfig = {
  // Your Firebase config object here
  apiKey: "AIzaSyA_6IxkPu_20OTANnRRumtqiyJLH1-KpMc",
  authDomain: "mfpacitan.firebaseapp.com",
  databaseURL: "https://mfpacitan-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mfpacitan",
  storageBucket: "mfpacitan.appspot.com",
  messagingSenderId: "1060769127857",
  appId: "1:1060769127857:web:1fea35750734674493b190",
  measurementId: "G-CSFG9N291L"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const LiveChartFirebase = () => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const [suhu, setSuhu] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const startingData = {
      labels: [],
      datasets: [
        {
          label: 'Suhu',
          backgroundColor: 'rgba(206, 70, 90, 1)',
          borderColor: 'rgba(220,220,220,0.8)',
          pointBackgroundColor: 'rgba(220,220,220,1)',
          pointBorderColor: '#fff',
          data: [],
        },
      ],
    };

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: startingData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
          },
          x: {
            type: 'time',
            display: true,
            title: {
              display: true,
              text: 'Date',
            },
          },
        },
      },
    });

    const updateChart = (snapshot) => {
      const data = snapshot.val();
      const time = DateTime.now().toISO();

      startingData.labels.push(time);
      startingData.datasets[0].data.push({
        x: time,
        y: data,
      });

      if (startingData.labels.length > 10) {
        startingData.labels.shift();
        startingData.datasets[0].data.shift();
      }

      chartRef.current.data.labels = [...startingData.labels];
      chartRef.current.data.datasets[0].data = [...startingData.datasets[0].data];
      chartRef.current.update();
    };

    const dataRef = ref(database, 'DATA_SENSOR/DATA_SENSOR_SUHU'); // Replace with your actual path
    onValue(dataRef, updateChart);

    const intervalId = setInterval(updateChart, 2000);

    return () => {
      clearInterval(intervalId);
      chartRef.current.destroy(); // Destroy the chart when the component is unmounted
    };
  }, []);

  return <canvas ref={canvasRef} />;
};

export default LiveChartFirebase;
