// import React from "react";
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import config from "./config";
import ChartKelembaban from "./components/ChartKelembaban";
import CombinedChartAndData from "./components/CombinedChartAndData";
import LiveChartFirebase from "./components/LiveChartFirebase";
import LiveChartRandom from "./components/LiveChartRandom";
import LineChart from "./components/LiveChartRandom";
import Test from "./components/Test";


const App = () => {
  return (
    <div>
        <div class="center">
          <h1>AgroDry Monitoring</h1>
        </div>
        <CombinedChartAndData />
    </div>
  );
}

export default App;