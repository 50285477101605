import React, { useState, useEffect, useRef } from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartType,
  LineController,
} from 'chart.js';
import Chart from 'chart.js/auto';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Title,
  Tooltip,
  Legend
);

const firebaseConfig = {
  // Your Firebase config object here
  apiKey: "AIzaSyA_6IxkPu_20OTANnRRumtqiyJLH1-KpMc",
  authDomain: "mfpacitan.firebaseapp.com",
  databaseURL: "https://mfpacitan-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mfpacitan",
  storageBucket: "mfpacitan.appspot.com",
  messagingSenderId: "1060769127857",
  appId: "1:1060769127857:web:1fea35750734674493b190",
  measurementId: "G-CSFG9N291L"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const LiveChartRandom = () => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const startingData = {
      labels: [new Date().toISOString()],
      datasets: [
        {
          label: "Suhu",
          backgroundColor: "rgba(206, 70, 90, 1)",
          borderColor: "rgba(220,220,220,0.8)",
          pointBackgroundColor: "rgba(220,220,220,1)",
          pointBorderColor: "#fff",
          data: [65, 59, 80, 81],
        }
      ]
    };

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: startingData,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100
            }
          }],
          xAxes: [{
            type: 'time',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Date'
            },
            ticks: {
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000'
              }
            }
          }]
        }
      }
    });

    const updateChart = () => {
      const lastDataIndex = startingData.labels.length;
      chartRef.current.data.datasets[0].data[lastDataIndex] = Math.random() * 100;
      chartRef.current.data.labels[lastDataIndex] = new Date().toISOString();

      if (startingData.labels.length > 10) {
        startingData.labels.shift();
        startingData.datasets[0].data.shift();
      }

      chartRef.current.update();
    };

    const intervalId = setInterval(updateChart, 2000);

    return () => {
      clearInterval(intervalId);
      chartRef.current.destroy(); // Destroy the chart when the component is unmounted
    };
  }, []);

  return <canvas ref={canvasRef} />;
};

export default LiveChartRandom;