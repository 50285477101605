import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { initializeApp } from 'firebase/app';


const firebaseConfig = {
    // Your Firebase config object here
    apiKey: "AIzaSyA_6IxkPu_20OTANnRRumtqiyJLH1-KpMc",
    authDomain: "mfpacitan.firebaseapp.com",
    databaseURL: "https://mfpacitan-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "mfpacitan",
    storageBucket: "mfpacitan.appspot.com",
    messagingSenderId: "1060769127857",
    appId: "1:1060769127857:web:1fea35750734674493b190",
    measurementId: "G-CSFG9N291L"
  };
  
  const firebaseApp = initializeApp(firebaseConfig);
const Test = () => {
  // Initialize state variables
  const [suhu, setSuhu] = useState(null);

  useEffect(() => {
    // Initialize Firebase database
    const database = getDatabase(firebaseApp);

    // Set up the listener for the 'suhu' data
    const suhuRef = ref(database, 'DATA_SENSOR/DATA_SENSOR_SUHU');
    const listenerSuhu = onValue(suhuRef, (snapshot) => {
      const value = snapshot.val();
      setSuhu(value);
    }, (error) => {
      console.error('Error reading suhu:', error.message);
      // Handle error (e.g., show a toast)
    });

    // Clean up the listener when the component is unmounted
    return () => {
      // Remove the listener
      off(suhuRef, 'value', listenerSuhu);
    };
  }, []); // Empty dependency array means this effect runs once, like componentDidMount

  return (
    <div>
      <p>Suhu: {suhu !== null ? suhu : 'Loading...'}</p>
    </div>
  );
};

export default Test;
